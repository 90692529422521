import { useContext } from "react";
import Footer from "src/client/components/Layout/Footer";
import HomeSectionOne from "src/client/sections/institutional/home/one";
import WhyChooseUsSection from "src/client/sections/institutional/WhyChooseUs";
import HomeSectionExames from "src/client/sections/institutional/home/exames-audiologicos";
import HomeSectionAparelhosAuditivos from "src/client/sections/institutional/home/aparelhos-auditivos";
import StripeReinforcement from "src/client/sections/institutional/StripeReinforcement";
import Testimonials from "src/client/sections/institutional/Testimonials";
import FormSection from "src/client/sections/institutional/Form";
import WhatsAppButton from "src/client/components/Layout/WhatsAppButton";
import { WrapperInstitutional } from "src/client/styles/admin";
import { ApplicationContext } from "src/client/contexts/ApplicationContext";
import Form7Days from "src/client/sections/institutional/Form7Days";

export default function InicioInstitutionalPage() {

	const { scroll, setScroll } = useContext(ApplicationContext);

 	return <>
		<WhatsAppButton />

		<WrapperInstitutional $scroll={scroll}  id="WrapperInstitutional" onScroll={(e: any) => setScroll(e.target.scrollTop)}>
			<HomeSectionOne />
			<WhyChooseUsSection />
			<HomeSectionAparelhosAuditivos />
			<HomeSectionExames />
			<Form7Days />
			<StripeReinforcement />
			<Testimonials />
			<FormSection />
			<Footer />
		</WrapperInstitutional>
	</>
}
