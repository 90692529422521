import { styled } from "styled-components";

export const WrapperInstitutional = styled.div<{ $scroll: number }>`
	overflow: auto;

	${({ $scroll }) => $scroll > 70 ? `
		height: calc(100vh - (var(--header-size) - 20px));
		@media (max-width: 991px) { height: calc(100vh - 80px); }
	` : `
		height: calc(100vh - (var(--header-size) + 0px));
		@media (max-width: 991px) { height: calc(100vh - 80px); }
	`};
`;

export const Wrapper = styled.div<{ $scroll: number }>`
	padding: 80px 0 180px;

	${({ $scroll }) => $scroll > 70 ? `
		height: calc(100vh - (var(--header-size) - 20px));
		@media (max-width: 991px) { height: calc(100vh - 80px); }
	` : `
		height: calc(100vh - (var(--header-size) + 0px));
		@media (max-width: 991px) { height: calc(100vh - 80px); }
	`};

	background: var(--color-background);
	overflow: auto;

	h1 {
		font-size: 30px;
	}

	@media (max-width: 991px) {
		height: 100vh;
		padding: 60px 0 260px;
	}

	@media (max-width: 768px) {
		h1 {
			font-size: 26px;
		}
	}

	@media (max-width: 576px) {
		h1 {
			font-size: 24px;
		}
	}
`;


export const DashboardOptions = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, 360px);
	gap: 20px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const DashboardOption = styled.div<{ $notification?: number }>`
	padding: 20px 30px;
	border-radius: 10px;
	background-color: #fff;
	height: 100px;
	box-shadow: 0 0 40px rgba(0,0,0,.05);

	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	position: relative;

	user-select: none;
	-o-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	border: 2px solid transparent;

	cursor: pointer;
	transition: 200ms ease-in-out;

	&:hover {
		border-color: #cfcfcf;
	}

	> :first-child {
		margin: 0 20px 0 -4px;
	}
	
	.number {
		font-size: 38px;
		font-weight: 800;
		text-align: center;
	}

	svg, .number {
		--size: 50px;
		width: var(--size);
		min-width: var(--size);
		height: var(--size);
	}

	h2 {
		font-size: 20px;
		margin: 0 0 3px;
	}

	p {
		font-size: 16px;
		font-weight: 700;
		line-height: 1.2;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	${({ $notification }) => ($notification && $notification > 0) && `
		&::after {
			content: '${$notification}';
			background: var(--color-primary);
			color: white;
			
			position: absolute;
			top: -5px;
			right: -5px;
			
			--size: 30px;
			min-width: var(--size);
			min-height: var(--size);
			width: max-content;
			max-height: var(--size);
			border-radius: 100%;
			font-size: calc(var(--size) - 12px);
			padding: 1px 0 0;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	`};

	@media (max-width: 480px) {
		svg, .number {
			--size: 40px;
		}
	}
`;

export const ListEmpty = styled.div`
	width: 100%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #c1cece;

	svg {
		height: 80px;
		margin: 0 0 30px;
	}

	h4 {
		font-size: 20px;
	}

	button {
		border-radius: 100px;
	}
`;

export const List = styled.div<{ columns?: number, gap?: number }>`
	display: grid;
	grid-template-columns: ${({ columns }) => `repeat(${columns || 1}, 1fr)`};
	gap: ${({ gap }) => `${gap || 0}px`};

	@media (max-width: 991px) {
		grid-template-columns: 1fr;
	} 
`;

export const DocumentItem = styled.div`
	padding: 10px;
	border-radius: 10px;
	margin: 0 0 10px;
	background: var(--color-white);

	--image-size: 50px;
	
	display: grid;
	grid-template-columns: var(--image-size) 2fr 100px 1fr 0 1fr;
	align-items: center;
	gap: 20px;

	.image {
		object-fit: cover;
		min-width: var(--image-size);
		max-width: var(--image-size);
		height: var(--image-size);
		
		border-radius: 8px;
		background-color: var(--color-border);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&.pdf {
			background: #8e5554;

			display: flex;
			justify-content: center;
			align-items: center;

			&::after {
				content: "PDF";
				font-size: 14px;
				color: #FFF;
			}
		}
	}

	.title {
		font-weight: 800;
		font-size: 16px;
		opacity: .9;
	}

	.date, .size {
		font-size: 16px;
		font-weight: 800;
		text-align: center;
		opacity: .9;
	}

	.actions {
		display: flex;
		justify-content: flex-end;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 10px;

			font-size: 14px;
			font-weight: 800;
			padding: 12px 20px;
			border: none;
			border-radius: 8px;

			opacity: .8;
			transition: 200ms ease-in-out;

			&:hover {
				opacity: 1;
			}

			cursor: pointer;

			svg {
				margin-right: 10px;

				--svg-size: 16px;
				min-width: var(--svg-size);
				max-width: var(--svg-size);
				height: var(--svg-size);

				fill: var(--color);
				
				path {
					fill: var(--color);
				}
			}

			&.download {
				background: #D9D9D9;
				color: var(--color);
			}

			&.remove {
				background: #C6AAA9;
				color: var(--color-primary);

				svg {
					fill: var(--color-primary);
				
					path {
						fill: var(--color-primary);
					}
				}
			}
		}
	}

	@media (max-width: 1280px) {
		grid-template-columns: auto 1fr;
	}

	@media (max-width: 991px) {
		gap: 10px;

		.title {
			text-align: right;
		}

		.date {
			text-align: right;
		}

		.size {
			text-align: left;
			font-size: 14px;
		}
		
		.actions {
			margin: 10px 0 0;
		}
	}

`;

export const Item = styled.div`
    padding: 8px 10px;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr auto;
    gap: 30px;
    border-bottom: 1px solid var(--color-border);
	background-color: transparent;

	span {
		font-weight: 700;
		font-size: 16px;
		line-height: 1.4;
		text-overflow: ellipsis;
		overflow: hidden;

		span {
			font-weight: 800;
		}
	}

	span.tag {
		display: block;
		padding: 4px 13px;
		background: #d9f3f3;
		border-radius: 20px;
		overflow: hidden;
		width: max-content;
		border: 1px solid rgb(130 177 177);
		color: #00312c;
		font-size: 15px;
		font-weight: 800;
	}

	.status {
		&.success { color: rgba(82, 171, 86, 1); }
		&.in-progress { color: rgba(220, 164, 77, 1); }
		&.analytics { color: rgba(142, 85, 84, 1); }
	}

	.data {
		display: flex;
		flex-direction: column;

		max-width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		min-width: 130px;

		button {
			--size: 40px;
			min-width: var(--size);
			max-width: var(--size);
			height: var(--size);
			border-radius: var(--size);
			padding: 12px;

			border: none;

			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 15px;
			font-weight: 800;

			cursor: pointer;
			background: var(--color-primary-light);
			color: #fff;

			transition: 200ms ease-in-out;

			&:hover {
				background: var(--color-primary-light);
			}

			svg {
				min-width: 100%;
				max-width: 100%;
				height: 100%;
				fill: #fff;

				path {
					fill: #fff;
				}
			}
		}
	}

	&:hover {
		transition: .2s ease-in-out;
		cursor: pointer;
		background: var(--color-hover);
	}

	&.head {
		background: var(--color-background);
		font-weight: 600;
		position: sticky;
		top: -20px;
		padding: 10px;
	}

	@media (max-width: 1280px) {
		grid-template-columns: 1fr 1fr;
		gap: 10px;
	}

	@media (max-width: 991px) {
		grid-template-columns: 1fr;
		gap: 10px;
	}

	@media (max-width: 380px) {
		padding: 15px;
	}
`;