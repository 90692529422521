import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// DEPENDENCIES
import reportWebVitals from './reportWebVitals';
import "./client/fonts/Lato/index.css";

// PAGES
import NotFound from './client/pages/institutional/not-found';
import InicioInstitutionalPage from './client/pages/institutional/home';
import ContatoInstitutionalPage from './client/pages/institutional/contato';
import QuemSomosInstitutionalPage from './client/pages/institutional/quem-somos';
import InstitutionalLayoutRoot from './client/pages/institutional/_layout';
import AdminLayoutRoot from './client/pages/admin/_layout';
import AdminHomePage from './client/pages/admin';
import AdminLeadsPage from './client/pages/admin/leads';
import AparelhosAuditivosInstitutionalPage from './client/pages/institutional/aparelhos-auditivos';
import ExamesAudiologicosInstitutionalPage from './client/pages/institutional/exames-audiologicos';
import DepoimentosInstitutionalPage from './client/pages/institutional/depoimentos';
import DashboardAdminPerfil from './client/pages/admin/perfil';
import AdminConfigsPage from './client/pages/admin/configs';
import ThanksSection from './client/sections/institutional/Thanks';

const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="*" element={<NotFound />} />
				
				{/* INSTITUTIONAL */}
				<Route path="/" element={<InstitutionalLayoutRoot children={<InicioInstitutionalPage />} title="Início" /> } />
				<Route path="/quem-somos" element={<InstitutionalLayoutRoot children={<QuemSomosInstitutionalPage />} title="Quem Somos" /> } />
				<Route path="/depoimentos" element={<InstitutionalLayoutRoot children={<DepoimentosInstitutionalPage />} title="Depoimentos" /> } />
				<Route path="/aparelhos-auditivos" element={<InstitutionalLayoutRoot children={<AparelhosAuditivosInstitutionalPage />} title="Aparelhos Auditivos" /> } />
				<Route path="/exames-audiologicos" element={<InstitutionalLayoutRoot children={<ExamesAudiologicosInstitutionalPage />} title="Exames" /> } />

				<Route path="/contato" element={<InstitutionalLayoutRoot children={<ContatoInstitutionalPage type="contato" />} title="Contato" /> } />
				<Route path="/orcamento" element={<InstitutionalLayoutRoot children={<ContatoInstitutionalPage type="orcamento" />} title="Contato" /> } />
				<Route path="/agendamento" element={<InstitutionalLayoutRoot children={<ContatoInstitutionalPage type="agendamento" />} title="Contato" /> } />
				
				<Route path="/obrigado_aparelhos" element={<InstitutionalLayoutRoot children={<ThanksSection />} title="Obrigado" /> } />
				<Route path="/obrigado_exames" element={<InstitutionalLayoutRoot children={<ThanksSection />} title="Obrigado" /> } />
				
				{/* ADMIN */}
				<Route path="/admin" element={<AdminLayoutRoot children={<AdminHomePage />} title="Visão Geral" />} />
				<Route path="/admin/leads" element={<AdminLayoutRoot children={<AdminLeadsPage />} title="Leads" />} />
				<Route path="/admin/perfil" element={<AdminLayoutRoot children={<DashboardAdminPerfil />} title="Perfil" />} />
				<Route path="/admin/configs" element={<AdminLayoutRoot children={<AdminConfigsPage />} title="Configurações" />} />
			</Routes>
		</Router>
	</React.StrictMode>
);

reportWebVitals();