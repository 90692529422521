import { styled } from "styled-components";
import { Button, Container } from "src/client/styles/layout";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "src/client/assets/images/home-one-banner.jpg";
import BackgroundImageMobile from "src/client/assets/images/home-one-banner-mobile.jpg";
import actions from "src/client/actions";

const Wrapper = styled.div`
  height: calc(100vh - var(--header-size));
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-light);
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  color: var(--color-white);

  position: relative;

  @media (max-width: 991px) {
    height: inherit;
    padding: 80px 0;
    align-items: flex-start;

    background-image: url(${BackgroundImageMobile});
    height: 1200px;
  }

  @media (max-width: 768px) {
    height: 1000px;
  }

  @media (max-width: 576px) {
    height: 800px;
    padding: 60px 0;
  }

  @media (max-width: 480px) {
    height: 720px;
  }
`;

const ContentWrapper = styled.div`
  max-width: 700px;
  text-align: left;
  z-index: 2;
  max-width: 640px;
  color: var(--color-white);

  h1 {
    font-size: 4rem;
    line-height: 1.2;
    font-weight: 400;

    span {
      font-weight: 800;
    }
  }

  p {
    max-width: 80%;
    font-size: 26px;
    margin: 20px 0 40px;
  }

  Button {
    margin: 0 0 100px;
  }

  @media (max-width: 1600px) {
    max-width: 600px;
    
    h1 {
      font-size: 3.4rem;
      margin: 0 auto;
    }
  }

  @media (max-width: 991px) {
    margin: 0 auto;
    text-align: center;
    
    h1 {
      max-width: 520px;
      font-size: 3rem;
    }

    p {
      margin: 20px auto 40px;
    }

    Button {
      margin: 0 auto 100px;
    }
  }

  @media (max-width: 768px) {
    h1 {
      max-width: 420px;
      font-size: 2.5rem;
    }

    p {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 576px) {
    h1 {
      max-width: 360px;
      font-size: 2.1rem;
    }

    p {
      font-weight: 700;
      font-size: 1.1rem;
      max-width: 360px;
    }

  }

`;

const HighlightText = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: var(--color-white);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 60px auto;
  width: 100%;
  text-align: center;
  padding: 30px;
  margin-bottom: 0;
  max-width: 560px;

  h3 {
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(255 255 255 / 20%);
    padding-bottom: 15px;
    max-width: 70%;
    margin: 0 auto 15px;
  }

  h4 {
    font-size: 1.4rem;
    font-weight: 400;
    margin: 10px 0 0;
    line-height: 1.3;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1.6rem;
    }
    h4 {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 576px) {
    h3 {
      font-size: 1.4rem;
    }
    h4 {
      font-size: 1.2rem;
    }
  }
`;

export default function HomeSectionOne() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <h1>Sinta a alegria de <span>ouvir os sons da vida com clareza e detalhes.</span></h1>
          <p>Somos especialistas na saúde auditiva e referência em diagnósticos e reabilitação.</p>
          <Button onClick={() => actions.whatsAppSend()}>Quero Agendar Agora</Button>
          <HighlightText>
            <h3>7 Unidades</h3>
            <h4>Estamos presentes em São Paulo (capital), na região do ABC (Grande São Paulo) e na Baixada Santista.</h4>
          </HighlightText>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
}