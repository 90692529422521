import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { GA_PAGEVIEW } from "src/client/analytics";
import { ApplicationProvider } from "src/client/contexts/ApplicationContext";

export default function InstitutionalLayoutRoot({ children, title }: any) {
  const location = useLocation();
  const lastPath = useRef<string | null>(null); // Guarda o último caminho registrado

  useEffect(() => {
    if (typeof window === "undefined" || !window.dataLayer) return;

    const currentPath = location.pathname + location.search;

    // Só envia se a URL for diferente da última registrada
    if (lastPath.current !== currentPath) {
      GA_PAGEVIEW(currentPath);
      lastPath.current = currentPath; // Atualiza o último caminho
    }
  }, [location]);

  return (
    <ApplicationProvider title={title}>
      {children}
    </ApplicationProvider>
  );
}
