import axios from "axios";
import * as configs from "../configs";
import { GA_SEND } from "./analytics";

const api = axios.create({
  	baseURL: configs.SERVER.HOSTNAME,
});

function whatsAppSend(number: string = "5511992449217") {
	
	// Enviar evento para o GA antes de abrir o link
	GA_SEND("clique_whatsapp");
	
	// Abrir nova aba
	const url = `https://api.whatsapp.com/send?phone=${number}&text=Clique%20no%20bot%C3%A3o%20ao%20lado%20para%20falar%20com%20o%20GrupoD%C3%AAOuvidos%20%3E%3E%3E`;
	window.open(url, "_blank", "noopener,noreferrer");
}

function emailSend(email: string = "contato@grupodeouvidos.com.br") {

	// Enviar evento ao GA antes de abrir o email
	GA_SEND("clique_email");

  	window.open(`mailto:${email}`, "_self");
}

function phoneSend(number: string) {
  	
	// Enviar evento ao GA antes de abrir o telefone
	GA_SEND("clique_telefone");

  	window.open(`tel:${number}`, "_self");
}

export default {
	api,
	whatsAppSend,
	emailSend,
	phoneSend,
};
