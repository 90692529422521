import { useContext, useEffect, useState } from "react";
import moment from "moment";
import 'moment/locale/pt-br';

import { ReactComponent as SearchIcon } from "src/client/assets/icons/search.svg";

import { ApplicationContext } from "src/client/contexts/ApplicationContext";
import { CategoryTitle, Container } from "src/client/styles/layout";
import { Wrapper, List, ListEmpty, Item } from "src/client/styles/admin";
import LoadingMoreItems from "src/client/components/Loading/MoreItems";
import actions from "src/client/actions";
import { ReactComponent as EyeIconSvg } from "src/client/assets/icons/eye.svg";
import { ILead } from "src/core/interfaces/ILead";
import SearchInput, { DateRangeWrapper, SearchButton, SearchFiltroOpcao, SearchFiltros } from "src/client/components/Inputs/search";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatFullName } from "src/core/helpers/format";
import Pagination from "src/client/components/Pagination";

interface PaginatedLeads {
    leads: ILead[];
    totalLeads: number;
    totalPages: number;
    currentPage: number;
}

export default function AdminLeadsPage() {
    const [list, setList] = useState<ILead[]>([]);
    const [search, setSearch] = useState<string>("");
    const [filters, setFilters] = useState<string[]>(JSON.parse(localStorage.getItem("leadFilters") || "[\"whatsapp\", \"site\"]"));
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(JSON.parse(localStorage.getItem("leadDateRange") || "[null, null]"));
    const [tempDateRange, setTempDateRange] = useState<[Date | null, Date | null]>(dateRange);
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [loadingMoreItems, setLoadingMoreItems] = useState(true);

    const { setLoading, scroll, setScroll, setConversations } = useContext(ApplicationContext);

    useEffect(() => {
        localStorage.setItem("leadFilters", JSON.stringify(filters));
		setPage(1);
    }, [filters]);

    useEffect(() => {
        getLeadsData();
    }, [page, filters, dateRange]);

    async function getLeadsData() {
        setLoading(true);
        try {
            const response: PaginatedLeads | null = await actions.api.get("/leads", {
                params: { search, page, filters, startDate: dateRange[0] || undefined, endDate: dateRange[1] || undefined }
            }).then((res) => res.data);

            if (response) {
                setList(response.leads);
                setTotalItems(response.totalLeads)
                setTotalPages(response.totalPages);
            }
        } catch (error) {
            console.error("Erro ao buscar leads:", error);
        } finally {
            setLoadingMoreItems(false);
            setLoading(false);
        }
    }

    function updateFilters(newValue: string) {
        setFilters(prev => prev.includes(newValue) ? prev.filter(f => f !== newValue) : [...prev, newValue]);
		setPage(1);
    }

    function handleDateChange(update: [Date | null, Date | null]) {
        setTempDateRange(update);
        
        if (!update[0] && !update[1]) { // Se ambas as datas forem removidas, buscar todos os registros
            setDateRange([null, null]);
            localStorage.removeItem("leadDateRange");
            setPage(1);
        } else if (update[0] && update[1]) { // Apenas salva e faz a busca quando ambas as datas forem definidas
            setDateRange(update);
            localStorage.setItem("leadDateRange", JSON.stringify(update));
            setPage(1);
        }
    }

    return (
        <Wrapper $scroll={scroll} onScroll={(e: any) => setScroll(e.target.scrollTop)}>
            <Container>
                <CategoryTitle style={{ marginTop: 0 }}>Leads</CategoryTitle>
                <SearchInput search={search} handleSearchChange={(e: any) => setSearch(e.target.value)} placeholder="Pesquisar leads...">
                    <SearchFiltros>
                        <SearchFiltroOpcao htmlFor="whatsapp" className={filters.includes("whatsapp") ? "active" : ""}>
                            <input id="whatsapp" type="checkbox" onChange={() => updateFilters("whatsapp")} checked={filters.includes("whatsapp")} />
                            <span>WhatsApp</span>
                        </SearchFiltroOpcao>
                        <SearchFiltroOpcao htmlFor="site" className={filters.includes("site") ? "active" : ""}>
                            <input id="site" type="checkbox" onChange={() => updateFilters("site")} checked={filters.includes("site")} />
                            <span>Site</span>
                        </SearchFiltroOpcao>
                    </SearchFiltros>
                    <DateRangeWrapper>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selectsRange
                            startDate={tempDateRange[0]}
                            endDate={tempDateRange[1]}
                            onChange={handleDateChange}
                            placeholderText="Período (início - fim)"
                            isClearable
                        />
                    </DateRangeWrapper>
                    <SearchButton onClick={getLeadsData}>
                        <button><SearchIcon /></button>
                    </SearchButton>
                </SearchInput>
                
                {list?.length > 0 ? (
                    <List>
                        <Item className="head">
                            <div className="data">
                            <span>Nome</span>
                            </div>
                            <div className="data">
                            <span>Origem</span>
                            </div>
                            <div className="data">
                            <span>Entrou em</span>
                            </div>
                            <div className="actions">
                            <span>Ações</span>
                            </div>
                        </Item>
                        {list.map((item: ILead, i: any) => (
                            <Client key={i} data={item} setConversations={setConversations} />
                        ))}
                    </List>
                ) : loadingMoreItems ? (
                    <LoadingMoreItems />
                ) : (
                    <ListEmpty>
                    <h4>Nenhum lead encontrado.</h4>
                    </ListEmpty>
                )}

                <Pagination
                        currentPage={page} 
                        totalPages={totalPages} 
                        totalItems={totalItems}
                        onPageChange={(newPage) => setPage(newPage)} 
                    />
            </Container>
        </Wrapper>
    );
}

const Client = ({ data, setConversations }: { data: ILead, setConversations(data: any): any }) => {
    moment.locale("pt-br");
    return (
        <Item onClick={() => setConversations({ visible: true, section: "client", data })}>
            <div className="data"><span>{formatFullName(data?.name)}</span></div>
            <div className="data"><span className="tag">{(data?.origin || "Desconhecido")?.toLowerCase()}</span></div>
            <div className="data"><span>{moment(data?.createdAt).calendar()}</span></div>
            <div className="actions">
                <button><EyeIconSvg /></button>
            </div>
        </Item>
    );
};
