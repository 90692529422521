import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

import { Button, Container } from "src/client/styles/layout";

import BackgroundImage from "src/client/assets/images/exames-audiologicos-banner.jpg";
import BackgroundImageMobile from "src/client/assets/images/exames-audiologicos-banner-mobile.jpg";
import { ReactComponent as CheckIcon } from "src/client/assets/icons/check-colored-green.svg";

const Wrapper = styled.div`
	min-height: calc(100vh - var(--header-size));
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary-light);
	background-image: url(${BackgroundImage});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	color: var(--color-white);

	position: relative;

	@media (max-width: 991px) {
    display: flex;
    align-items: flex-start;

		padding: 100px 0;
    background-image: url(${BackgroundImageMobile});
    background-position: center bottom;
	}

	@media (max-width: 576px) {
		padding: 60px 0;
	}
`;

const ContentWrapper = styled.div`
    margin: 0 auto 0 0;
    color: var(--color-white);

    h1 {
        font-size: 4.5rem;
        font-weight: 400;
        max-width: 540px;
        line-height: 1.1;

        span {
          font-weight: 900;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 60px 0;

        li {
          display: flex;
          align-items: center;
          font-size: 1.8rem;
          font-weight: 500;
          margin-bottom: 20px;
          max-width: 560px;

          svg {
            margin-right: 15px;
            
            --size: 30px;
            height: var(--size);
            max-width: var(--size);
            min-width: var(--size);
          }
        }
    }

    @media (max-width: 1400px) {
        h1 {
            max-width: 480px;

            span {
              font-weight: 800;
            }
        }
    }

    @media (max-width: 992px) {
        text-align: center;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        ul {
          margin: 40px 0;
          text-align: left;
        }
    }

    @media (max-width: 576px) {
        h1 {
            font-size: 3rem;
            max-width: 320px;
        }

        ul {
          margin: 30px 0;

          li {
            font-size: 1.2rem;
            margin-bottom: 14px;

            svg {
              --size: 25px;
            }
          }
        }
    }

    @media (max-width: 480px) {
        h1 {
            font-size: 2rem;
            max-width: 300px;
        }

        ul {
          li {
            font-size: 1.1rem;
            margin-bottom: 10px;

            svg {
              --size: 25px;
            }
          }
        }
    }
`;

export default function ExamesAudiologicosSectionOne() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <h1>Exames <span>Audiológicos</span></h1>
          <ul>
            <li><CheckIcon /> Exames completos, resultado na hora!</li>
            <li><CheckIcon /> Atendimento ágil e eficiente</li>
            <li><CheckIcon /> Acompanhamento especializado</li>
          </ul>
          <Button onClick={() => navigate("/agendamento")}>Quero agendar agora</Button>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
}