import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    margin: 20px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;

const PageButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #0a8172;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s ease;
  
  &:hover {
    background: #e6f0ff;
  }
  
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
    background: none;
  }
`;

const PageNumber = styled.button<{ isActive: boolean }>`
  background: ${(props) => (props.isActive ? "#0a8172" : "white")};
  color: ${(props) => (props.isActive ? "white" : "#0a8172")};
  border: 1px solid #0a8172;
  font-size: 16px;
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
  
  &:hover {
    background: ${(props) => (props.isActive ? "#0a8172" : "#e6f0ff")};
  }
`;

const Ellipsis = styled.span`
  font-size: 16px;
  padding: 5px;
  color: #666;
`;

const TotalRecords = styled.span`
  margin-left: 10px;
  font-weight: bold;
  color: #055c51;
`;

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, totalItems, onPageChange }) => {
  const getPageNumbers = () => {
    const pages: (number | string)[] = [];

    if (totalPages <= 7) {
      // Mostra todas as páginas se forem 7 ou menos
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage > 2) pages.push(1); // Sempre mostra a primeira página
      if (currentPage > 3) pages.push("..."); // Reticências quando necessário

      let start = Math.max(2, currentPage - 1);
      let end = Math.min(totalPages - 1, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - 2) pages.push("...");
      if (currentPage < totalPages - 1) pages.push(totalPages);
    }

    return pages;
  };

  return (
    <Wrapper>
      <TotalRecords>{totalItems} registros encontrados</TotalRecords>

        { totalItems > 0 ? 
            <PaginationWrapper>
            <PageButton disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
                {"<"}
            </PageButton>

            {getPageNumbers().map((page, index) =>
                typeof page === "number" ? (
                <PageNumber 
                    key={index} 
                    isActive={currentPage === page} 
                    onClick={() => onPageChange(page)}
                >
                    {page}
                </PageNumber>
                ) : (
                <Ellipsis key={index}>...</Ellipsis>
                )
            )}

            <PageButton disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)}>
                {">"}
            </PageButton>
            </PaginationWrapper>
        : <></>}
    </Wrapper>
  );
};

export default Pagination;
