import { useState, useEffect } from "react";
import { styled } from "styled-components";

import { Button, Container } from "src/client/styles/layout";
import Input from "src/client/components/Inputs/official";
import TextArea from "src/client/components/Inputs/textarea";
import InputPhone from "src/client/components/Inputs/telephone";

import BackgroundPattern from "src/client/assets/images/background-pattern.png";
import FormImage from "src/client/assets/images/form-image.jpg";
import actions from "src/client/actions";
import { GA_SEND } from "src/client/analytics";
import { LeadValidation } from "src/core/helpers/validations/lead";
import useRouter from "src/client/hooks/useRouter";
import { useLocation } from "react-router-dom";

const SectionWrapper = styled.section`
    padding: 120px 0;
    background-image: url(${BackgroundPattern});
    background-size: cover;
    background-position: center;
    background-color: var(--color-primary-light);
    display: flex;
    justify-content: center;
    align-items: center;
	overflow: hidden;

	@media (max-width: 1200px) {
		padding: 60px 0;
	}
	
    @media (max-width: 576px) {
        padding: 20px 0;
    }
`;

const Box = styled.div`
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	background: #f3f3f3;
	display: grid;
	grid-template-columns: 1fr 1fr;
    border-radius: 20px;
	overflow: hidden;
	box-shadow: 0 0 100px rgba(0,0,0,.05);

	@media (max-width: 1200px) {
		grid-template-columns: 1fr 1.2fr;
	}

	@media (max-width: 992px) {
		grid-template-columns: 1fr;
		border-radius: 16px;
	}

	@media (max-width: 576px) {
		margin: 0 -10px;
		width: calc(100% + 20px);
    }
`;

const ImageWrapper = styled.div`
	width: 100%;
	
	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	@media (max-width: 992px) {
		img {
			height: 200px;
			object-position: center 30%;
		}
	}
`;

const FormWrapper = styled.div`
	width: 100%;
    text-align: center;
    color: var(--color-primary);
    padding: 80px 60px;

	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;

	@media (max-width: 1200px) {
		padding: 60px 40px;
	}

	@media (max-width: 768px) {
		padding: 40px 15px 15px;
	}

	@media (max-width: 576px) {
		Button {
			padding: 20px 10px;
			font-size: 1.2rem;
		}
    }
`;

const Title = styled.h2`
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 10px;

	@media (max-width: 1200px) {
		font-size: 2rem;
	}

	@media (max-width: 576px) {
		font-size: 1.7rem;
	}
`;

const Subtitle = styled.p`
    font-size: 1.4rem;
    margin-bottom: 30px;
    max-width: 420px;
    margin: 0 auto 40px;

	@media (max-width: 1200px) {
		font-size: 1.2rem;
		max-width: 360px;
	}

	@media (max-width: 420px) {
		font-size: 1rem;
		font-weight: 400;
		max-width: 300px;
	}
`;


const StyledForm = styled.form`
	width: 100%;

    .textarea ~ ._label {
        background-color: var(--color-white);
    }

    Button {
        margin: 10px 0 0;
        max-width: 100%;
        width: 100%;
    }
`;


export default function FormSection({ title = "Solicite um orçamento" }: { title?: string }) {

	const [form, setForm] = useState({}) as any;
	const [errors, setErrors] = useState({}) as any;
	const [loading, setLoading] = useState(false) as any;
	const [success, setSuccess] = useState(false) as any;
	
	const initialMessage = "";

	const router = useRouter();
	const location = useLocation();

	async function handleSubmit(ev: any) {
		ev.preventDefault();

		if (loading) return;    
		setLoading(true);

		try {
			LeadValidation(form);

			await actions.api.post("/leads", form).then(response => {
				if (!response.data.success) throw response.data;
				GA_SEND("envio_formulario");

				var examesPage = location.pathname.startsWith("/exames");
				router(`/obrigado_${examesPage ? 'exames' : 'aparelhos'}`);
			});
		} catch (e: any) {
			setErrors(e.response?.data?.message || e);
			setLoading(false);
		}
	}

	useEffect(() => {
		setErrors({});
	}, [form]);

	return (
    <SectionWrapper>
		<Container>
			<Box>
				<ImageWrapper>
					<img src={FormImage} alt="GrupoDêOuvidos - Ouvindo a vida" />
				</ImageWrapper>
				<FormWrapper>
					<Title>{title}</Title>
					<Subtitle>Preencha os campos abaixo e entraremos em contato o mais rápido possível.</Subtitle>
					<StyledForm onSubmit={handleSubmit}>
						<Input 
						error={errors?.name} 
						type="text" 
						placeholder="Nome completo *" 
						textTransform="capitalize"
						onChange={(e: any) => setForm({ ...form, name: e.target.value })} 
						/>
						<Input 
						error={errors?.email} 
						type="email" 
						placeholder="E-mail *"
						textTransform="lowercase"
						onChange={(e: any) => setForm({ ...form, email: e.target.value })} 
						/>
						<InputPhone 
						error={errors?.phone} 
						onChange={(phone: any) => setForm({ ...form, phone })} 
						/>
						<TextArea 
						error={errors?.message} 
						placeholder="Mensagem *" 
						initialValue={initialMessage} 
						onChange={(e: any) => setForm({ ...form, message: e.target.value })} 
						limit={1000} 
						/>
						<Button loading={loading ? true : undefined}>Enviar</Button>
					</StyledForm>
				</FormWrapper>
			</Box>
		</Container>
    </SectionWrapper>
  );
}