declare global {
    interface Window {
      gtag: (...args: any[]) => void;
      dataLayer: Record<string, any>[];
    }
  }
  
  export const GTM_ID = "GTM-KMCCCN7";
  
  // Verifica se o GTM foi carregado
  const isGTMReady = (): boolean => {
    return typeof window !== "undefined" && Array.isArray(window.dataLayer);
  };
  
  // Verifica se o gtag foi carregado (GA4 via GTM)
  const isGtagLoaded = (): boolean => {
    return typeof window !== "undefined" && typeof window.gtag === "function";
  };
  
  // Função para registrar visualizações de página
  export const GA_PAGEVIEW = (url: string): void => {
    if (isGtagLoaded()) {
      window.gtag("config", GTM_ID, { page_path: url });
    } else if (isGTMReady()) {
      window.dataLayer.push({ event: "pageview", page_path: url });
    } else {
      console.warn("Google Tag Manager não carregado.");
    }
  };
  
  // Função para enviar eventos personalizados
  export const GA_SEND = (eventName: string): void => {

    window.dataLayer.push({ event: eventName });
  };
  