import { styled } from "styled-components";

import { ReactComponent as SearchIcon } from "src/client/assets/icons/search.svg";
import Input from "./official";

const Wrapper = styled.div`
    display: grid;
	grid-template-columns: 2fr 1fr 1fr auto;
	gap: 40px;

	position: sticky;
    top: -80px;
    z-index: 998;
    margin: 0 0 20px;
    background: white;
    border: 1px solid var(--color-border);
`;

export const SearchFiltros = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const SearchFiltroOpcao = styled.label`
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: pointer;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    border-radius: 50px;

	* {
		cursor: pointer;
	}

	input {
		width: clamp(16px, 16px, 16px);
		height: clamp(16px, 16px, 16px);
	}

	span {
		padding: 0 8px;
		font-weight: 700;
		color: rgba(0,0,0,.7);
	}

	&:hover {
		background: var(--color-hover);
	}

	&.active {
		background: var(--color-hover);
		border: 1px solid rgb(130 177 177);
		color: #00312c;
	}
`;


export const DateRangeWrapper = styled.div`
    margin: 10px 0 0 -10px;
    position: relative;

	> div,
	> div > div {
		width: 100%;
		max-width: 100%;
	}

	input {
		font-size: 15px;
		font-weight: 700;
		width: 100%;
		border: 1px solid rgba(0,0,0,.2);
		border-radius: 6px;
		padding: 10px;
	}

	.react-datepicker__close-icon::after {
		background-color: #0a8172;
	}
`;

export const SearchButton = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-width: 130px;
	padding: 0 10px 0 0;

	button {
		--size: 40px;
		min-width: var(--size);
		max-width: var(--size);
		height: var(--size);
		border-radius: var(--size);
		padding: 12px;

		border: none;

		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		font-weight: 800;

		cursor: pointer;
		background: var(--color-primary);
		color: #fff;

		transition: 200ms ease-in-out;

		&:hover {
			background: var(--color-primary-light);
		}

		svg {
			min-width: 100%;
			max-width: 100%;
			height: 100%;
			stroke: #fff;

			path {
				stroke: #fff;
			}
		}
	}
`;

export default function SearchInput({ search, handleSearchChange, children }: any) {

	return <Wrapper>
		<Input
			className="noBorder"
			icon={<SearchIcon />}
			type="text"
			placeholder="Pesquise por nome, telefone ou e-mail"
			value={search}
			onChange={handleSearchChange}
			/>
		
		{children}
	</Wrapper>
}