import { styled } from "styled-components";
import { Button, Container } from "src/client/styles/layout";
import useRouter from "src/client/hooks/useRouter";
import confetti from "canvas-confetti";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
	height: calc(100vh - var(--header-size));
	display: flex;
	align-items: center;
	position: relative;

	@media (max-width: 991px) {
		height: inherit;
		height: 900px;
		flex-direction: column;
	}

	@media (max-width: 576px) {
		height: 980px;
	}
`;

const WrapperText = styled.div`
	padding: 40px 0 100px;
	max-width: 600px;
	margin: 0 auto;
	z-index: 2;
	position: relative;

	.banner {
		background-color: rgba(0,0,0,.15);
		border-radius: 12px;
		margin-bottom: 40px;
		width: 100%;
		object-fit: contain;
	}
	
	h1 {
		font-size: 40px;
		font-weight: 800;
		color: var(--color-primary-light);
		margin-bottom: 10px;

		span {
			color: var(--color-primary);
		}
	}

	p {
		font-size: 26px;
		margin: 0;
	}

	Button {
		margin: 30px 0;
		background: #00bdac;
		border-color: #009386;
		box-shadow: none;
		font-size: 18px;
		padding: 17px 35px;
	}

	@media (max-width: 576px) {

		p {
			font-size: 22px;
		}
	}

`;

export default function ThanksSection()
{
	const router = useRouter();

	useEffect(() => {
		confetti({
		  particleCount: 100,
		  spread: 70,
		  origin: { y: 0.6 },
		});
	}, []);
	
	return <>
		<Wrapper>
			<Container style={{
				position: "relative",
				height: "100%",
				display: "flex"
			}}>
				<WrapperText>
					<img className="banner" src="/thanks-banner.jpg" />
					<h1>Obrigado!</h1>
					<p>Recebemos suas informações e vamos entrar em contato o mais rápido possível.</p>
					<Button onClick={() => router('/')}>Voltar ao site</Button>
				</WrapperText>
			</Container>
		</Wrapper>
	</>
}